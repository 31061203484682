/**
 * 使用、导出路由，页面组件（main.js调用）
 * ++ 包含菜单栏对应的路由及其子路由;
 * ++ 包含特殊路由，如404、登录、注册等不是通用页面;
 * ++ 路由中设置 name：菜单名字，icon：菜单图标，hiddenInMenu: (true/false)是否当作菜单等;
 */

import Vue from "vue";
import Router from "vue-router";
import store from '../store'
// import Layout from "../views/layout/layout.vue"; // 包含表格的页面的 基础布局组件

Vue.use(Router);

const router = new Router({
	routes: [
		{
			path: "/",
			name: "index",
			hiddenInMenu: true,
			redirect: '/auth'
		},
		// hiddenInMenu: true 在 sidebar 菜单栏中隐藏
		{
			path: "/404",
			component: () => import("@/404.vue"),
			hiddenInMenu: true
		},
		{
			path: "/login",
			name: "login",
			hiddenInMenu: true,
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("../views/login/")
		},
		{
			path: "/coachLogin",
			name: "coachLogin",
			hiddenInMenu: true,
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("../views/coachLogin/")
		},
		{
			path: "/hljJpLogin",
			name: "hljJpLogin",
			hiddenInMenu: true,
			// route level code-splitting
			// this generates a separate chunk (about.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("../views/hljJpLogin/")
		},
		{
			path: "/train",
			name: "train",
			hiddenInMenu: true,
			component: () => import("../views/train-list")
		},
		{
			path: "/jumptest",
			name: "jumptest",
			hiddenInMenu: true,
			component: () => import("../views/jump-test")
		},
		{
			path: "/score-list",
			name: "score-list",
			hiddenInMenu: true,
			component: () => import("../views/score")
		},
		{
			path: "/auth",
			name: "auth",
			hiddenInMenu: true,
			component: () => import("../views/auth/index.vue")
		},
		{
			path: "/city",
			name: "city",
			hiddenInMenu: true,
			component: () => import("../views/auth/city.vue")
		},
		{
			path: "/face",
			name: "face",
			hiddenInMenu: true,
			component: () => import("../views/face.vue")
		},
		{
			path: "/video",
			name: "video",
			hiddenInMenu: true,
			component: () => import("../views/video/index.vue")
		},
		{
			path: "/questiontest",
			name: "questiontest",
			hiddenInMenu: true,
			component: () => import("../views/questiontest/index.vue")
		},
		/*** 重绘视频播放页面 ***/
		{
			path: "/study",
			name: "study",
			hiddenInMenu: true,
			component: () => import("@/views/study/index.vue")
			// component: () => import("@/views/study/study-dev.vue")
		},
		{
			path: "/study1",
			name: "study1",
			hiddenInMenu: true,
			component: () => import("@/views/study1/index.vue")
			// component: () => import("@/views/study/study-dev.vue")
		},
		{
			path: "/question",
			name: "question",
			hiddenInMenu: true,
			component: () => import("@/views/question/index.vue")
			// component: () => import("@/views/study/study-dev.vue")
		},
		{
			path: "/study-demo",
			name: "study-demo",
			hiddenInMenu: true,
			component: () => import("@/views/study/study-demo.vue")
			// component: () => import("@/views/study/study-dev.vue")
		},
		/*** 重绘视频播放页面 ***/
		{
			path: "/test",
			name: "test",
			hiddenInMenu: true,
			component: () => import("../views/test/index.vue")
		},
		{
			path: "/score",
			name: "score",
			hiddenInMenu: true,
			component: () => import("../views/test/score.vue")
		},
		{
			path: "/result",
			name: "result",
			hiddenInMenu: true,
			component: () => import("../views/test/result.vue")
		},
		{
			path: "/exam",
			name: "exam",
			hiddenInMenu: true,
			component: () => import("../views/test/exam.vue")
		},
		{
			path: "/mock",
			name: "mock",
			hiddenInMenu: true,
			component: () => import("../views/test/test.vue")
		},
		{
			path: '/payCourse',
			name: 'payCourse',
			hiddenInMenu: true,
			component: () => import("../views/pay/payCourse.vue")
		},
		{
			path: '/pay',
			name: 'pay',
			hiddenInMenu: true,
			component: () => import("../views/pay/pay.vue")
		},
		{
			path: '/studyReport',
			name: 'study-report',
			hiddenInMenu: true,
			component: () => import("../views/study-report.vue")
		},
		{
			path: '/studyCertificate/:id',
			name: 'study-certificate',
			hiddenInMenu: true,
			component: () => import("../views/study-certificate.vue")
		},
		{
			path: '/coachCertificate',
			name: 'coach-certificate',
			hiddenInMenu: true,
			component: () => import("../views/coach-certificate.vue")
		},
		{
			path: '/studyCertificateList/:id',
			name: 'study-certificate-list',
			hiddenInMenu: true,
			component: () => import("../views/study-certificate-list.vue")
		},
		{
			path: '/studyCertificateQuery',
			name: 'study-certificate-query',
			hiddenInMenu: true,
			component: () => import("../views/study-certificate-query.vue")
		},
		{
			path: '/contract',
			name: 'contract',
			hiddenInMenu: true,
			component: () => import("../views/contract.vue")
		},
		{
			path: '/privacy-policy',
			name: 'privacyPolicy',
			hiddenInMenu: true,
			component: () => import("../views/privacy-policy"),
			meta: {
				title: '隐私政策'
			}
		},
		{
			path: '/service-agreement',
			name: 'serviceAgreement',
			hiddenInMenu: true,
			component: () => import("../views/privacy-policy"),
			meta: {
				title: '服务协议'
			}
		},
		{
			path: '/signup/wuhai',
			name: 'signup-wuhai',
			hiddenInMenu: true,
			component: () => import("../views/signup/wuhai.vue")
		},
		{
			path: '/signup/wuhai_certificate',
			name: 'wuhai-certificate',
			hiddenInMenu: true,
			component: () => import("../views/signup/wuhai_certificate.vue")
		},
		{
			path: '/signup/wuhai_certificate1',
			name: 'wuhai-certificate1',
			hiddenInMenu: true,
			component: () => import("../views/signup/wuhai_certificate1.vue")
		},
		{
			path: '/signup/wuhai_certificate2',
			name: 'wuhai-certificate2',
			hiddenInMenu: true,
			component: () => import("../views/signup/wuhai_certificate2.vue")
		},
		{
			path: '/signup/wuhai_push',
			name: 'signup-wuhai-push',
			hiddenInMenu: true,
			component: () => import("../views/signup/wuhai_push.vue")
		},
		{
			path: '/signup/shuangpu',
			name: 'signup-shuangpu',
			hiddenInMenu: true,
			component: () => import("../views/signup/shuangpu.vue")
		},
		{
			path: '/signup/shuangpu_push',
			name: 'signup-shuangpu-push',
			hiddenInMenu: true,
			component: () => import("../views/signup/shuangpu_push.vue")
		},
		{
			path: '/signup/liaoning_push',
			name: 'signup-liaoning-push',
			hiddenInMenu: true,
			component: () => import("../views/signup/liaoning_push.vue")
		},
		{
			path: '/signup/shandong_push',
			name: 'signup-shandong_push',
			hiddenInMenu: true,
			component: () => import("../views/signup/shandong_push.vue")
		},
		{
			path: '/shandong-center',
			name: 'shandong-center',
			hiddenInMenu: true,
			component: () => import("../views/shandong/shandong-center.vue")
		},
		{
			path: '/coach_certificate',
			name: 'coach-certificate-query',
			hiddenInMenu: true,
			component: () => import("../views/shandong/coach-certificate-query.vue")
		},
		{
			path: '/shandong-khcenter',
			name: 'shandong-khcenter',
			hiddenInMenu: true,
			component: () => import("../views/shandong/shandong-khcenter.vue")
		},
		{
			path: '/study-center',
			name: 'study-center',
			hiddenInMenu: true,
			component: () => import("../views/study-center.vue")
		},
		{
			path: '/plan-select',
			name: 'plan-select',
			hiddenInMenu: true,
			component: () => import("../views/plan/select.vue")
		},
		{
			path: '/electronic-sign',
			name: 'HandwrittenSignature',
			hiddenInMenu: true,
			component: () => import("../views/plan/sign.vue")
		},
		// {
		//   path: "/itemTags",
		//   name: "商品标签",
		//   icon: "el-icon-s-tools",
		//   component: Layout,
		//   redirect: "/itemTags/",
		//   children: [{
		//     path: "",
		//     name: "商品标签列表",
		//     component: () => import("../views/itemTags")
		//   }]
		// },
		{
			path: "/viplist",
			name: "viplist",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-list.vue")
		},
		{
			path: "/vipchoose",
			name: "vipchoose",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-choose.vue")
		},
		{
			path: "/vipchooseyunnan",
			name: "vipchooseyunnan",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-choose-yunnan.vue")
		},
		{
			path: "/vipquintessence",
			name: "vipquintessence",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-quintessence.vue")
		},
		{
			path: "/vipexam",
			name: "vipexam",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-exam.vue")
		},
		{
			path: "/viptestexam",
			name: "viptestexam",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-test-exam.vue")
		},
		{
			path: "/viplogin",
			name: "viplogin",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-login.vue")
		},
		{
			path: "/viphome",
			name: "viphome",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-home.vue")
		},
		{
			path: "/viphomeyunnan",
			name: "viphomeyunnan",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-home-yunnan.vue")
		},
		{
			path: "/viporder",
			name: "viporder",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-order.vue")
		},
		{
			path: "/vipsimulation",
			name: "vipsimulation",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-simulation.vue")
		},
		{
			path: "/vipsecret",
			name: "vipsecret",
			hiddenInMenu: true,
			component: () => import("../views/VIP/vip-secret.vue")
		},
		{
			path: "/shandong",
			name: "shandong",
			hiddenInMenu: true,
			component: () => import("../views/shandong/index.vue")
		},
		{
			path: "/shandongQuery",
			name: "shandong-query",
			hiddenInMenu: true,
			component: () => import("../views/shandong/query.vue")
		},
		{
			path: "/yunnanCoach",
			name: "yunnan-coach",
			hiddenInMenu: true,
			component: () => import("../views/yunnan/coach.vue")
		},
		{
			path: "/invoice",
			name: "invoice",
			hiddenInMenu: true,
			component: () => import("../views/invoice/index.vue")
		},
		{
			path: "/period",
			name: "period",
			hiddenInMenu: true,
			component: () => import("../views/period/index.vue")
		},
		{
			path: "/periodGD",
			name: "periodGD",
			hiddenInMenu: true,
			component: () => import("../views/period/guangdong.vue")
		},
		{
			path: "*",
			name: "404",
			hiddenInMenu: true,
			component: () => import("@/404.vue")
		}
	]
})

let isAuth = false
router.beforeEach((to, form, next) => {
	if (form.query.appno) to.query.appno = form.query.appno
	if (to.path === '/auth' && !isAuth) {
		isAuth = true
		const screen = navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
		const isWx = screen ? screen[0] === 'micromessenger' ? true : false : false
		const appNo = to.query.appNo || sessionStorage.getItem('appno') || 'APP01';
		const loginInfor = JSON.parse(localStorage.getItem('loginInfor_' + appNo))
		if (loginInfor && loginInfor.token) {
			store.dispatch('Login', {
				appNo,
				token: loginInfor.token
			}).then((data) => {
				if ( appNo === 'APP03' ) {
					// 企业安全培训转去选择学习计划
					next('/plan-select')
				} else {
					const payState = data.data[0].studentPayStatus
					if (String(data.isPassed) === '1' && String(payState) !== '0') {
						next('/train')
					} else {
						next('/auth')
					}
				}
			}).catch(() => {
				localStorage.removeItem('loginInfor_' + appNo)
				next(isWx ? { path: '/login', query: { appNo }} : { path: '/auth', query: { appNo }})
				// next(isWx ? '/auth' : '/auth')
			});
		} else {
			next(isWx ? { path: '/login', query: { appNo }} : { path: '/auth', query: { appNo }})
			// next(isWx ? '/auth' : '/auth')
		}
	}
	next()
})

export default router
